<template>
  <v-container fluid>
    <settings-miner-table
      :headers="headers"
      :expanded-headers="expanded"
      :data="data"
      :loading="loading"
      :table-page="page"
      :table-page-total-count="pageTotalCount"
      :table-size="size"
      @change="loadData($event.page, $event.size, $event.order, $event.orderBy)"
      @cancelDelete="cancelDelete($event)"
      @deleteMiner="deleteMiner($event)"
    />
  </v-container>
</template>

<script>
import settingsMinerTable from "@/views/settings/miners/table";
import { getMiners } from "@/api/settings/miner";
import { deleteMiner } from "@/api/settings/miner";

import { Errors } from "@/utils/error";

export default {
  components: {
    settingsMinerTable,
  },
  data() {
    return {
      headers: [],
      expanded: [],
      data: [],
      loading: true,

      page: 1,
      pageTotalCount: 1,
      size: 25,
      sizeTotalCount: 0,
    };
  },
  computed: {
    changeInAddForm: {
      get() {
        return this.$store.state.settings.changeInAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "changeInAddForm",
          value: val,
        });
      },
    },
    settingsMinerTable: {
      get() {
        return this.$store.state.tables.settingsMinerTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "settingsMinerTable",
          value: val,
        });
      },
    },
  },
  watch: {
    settingsMinerTable: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          const self = this;
          this.headers = [];
          newValue.headers.forEach(function (header) {
            if (header.visible) {
              self.headers.push(header);
            }
          });
          this.expanded = [];
          newValue.expanded.forEach(function (item) {
            if (item.visible) {
              self.expanded.push(item);
            }
          });
        }
      },
    },
    changeInAddForm: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue === "miner") {
          this.loadData(this.page, this.size);
        }
        this.changeInAddForm = "";
      },
    },
  },
  created() {
    this.loadData(this.page, this.size);
  },
  methods: {
    loadData(page, size, sortOrder, sortBy) {
      this.loading = true;
      let newPage = page;
      if (size !== this.size) {
        newPage = 1;
      }
      let order = null;
      let by = null;
      if (sortOrder && sortBy) {
        order = sortOrder;
        by = sortBy;
      }
      getMiners(newPage, size, order, by)
        .then((response) => {
          this.loading = false;
          this.data = response.data;
          this.data.forEach(function (value) {
            value.deleteMenu = false;
          });
          this.pageTotalCount = response.last_page;
          this.page = response.current_page;
          this.size = parseInt(response.per_page);
          this.sizeTotalCount = response.total;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
    cancelDelete(item) {
      const data = [];
      this.data.forEach(function (value) {
        if (item.id === value.id) {
          value.deleteMenu = false;
        }
        data.push(value);
      });
      this.data = [];
      this.$nextTick(() => {
        this.data = data;
      });
    },
    deleteMiner(item) {
      this.loading = true;
      const data = [];
      this.data.forEach(function (value) {
        if (item.id === value.id) {
          value.deleteMenu = false;
        }
        data.push(value);
      });
      this.data = [];
      this.$nextTick(() => {
        this.data = data;
      });
      if (item.deletable === 1) {
        deleteMiner(item.id)
          .then((response) => {
            if (response.type === "success") {
              this.$toast.open({
                message: "Miner was successfully deleted!",
                type: "success",
                position: "top-right",
                duration: 2000,
              });
            } else {
              Errors.show(response);
            }
            if (this.data.length === 1 && this.page !== 1) {
              this.loadData(this.page - 1, this.size, this.activeTab);
            } else {
              this.loadData(this.page, this.size, this.activeTab);
            }
          })
          .catch((e) => {
            Errors.show(e);
            const data = [];
            this.data.forEach(function (value) {
              data.push(value);
            });
            this.data = [];
            this.$nextTick(() => {
              this.data = data;
              this.loading = false;
            });
          });
      } else {
        this.$toast.open({
          message: "This miner can't be deleted!",
          type: "error",
          position: "top-right",
          duration: 2000,
        });
      }
    },
  },
};
</script>
